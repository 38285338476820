.section-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 2rem;
    width: 100%;
}

.section-header p{
    text-transform: uppercase;
    font-size: larger;
    letter-spacing: var(--letter-spacing);
    font-family: var(--secondary-font);
}

.section-header hr{
    flex: 1;
    margin: 0 1rem;
}