footer{
    background-color: #262626;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    box-sizing: border-box;
    border-top: inset 1px solid #fff;
    color: #fff;
}

footer.hide{
    background-color: #f00;
}

footer > div{
    display: flex;
    justify-content: space-around;
}

.footer-container{
    padding:2em 1em;
}

.footer-container > *{
    margin: 0;
}

footer a{
    color:#fff;
}

.footer-container > form{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.footer-container form div{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 1ch;
}

.footer-container form div label{
    margin-bottom: 0.5ch;
}

.footer-container textarea{
    resize: none;
    height: 5em;
}

.footer-container input, .footer-container textarea{
    padding: 0.5em;
    border: 2px solid #000;
    background-color: #A0A09F;
    color: #fff;
    transition: all 0.2s ease-in-out;
}

.footer-container input:focus, .footer-container textarea:focus{
    outline: none;
    border: 2px solid #fff;
}