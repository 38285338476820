.service-item{
    display: flex;
    flex-direction: column;
    width: calc(33vw - 1.4em);
    box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.25);
    height: 40em;
    gap: 1em;
    border: thin solid #ccc;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;
    text-decoration: none;
}

.service-item:hover{
    background-color: #ccc;
}

.service-item img{
    width: 100%;
    height: 22em;
    aspect-ratio: 1;
    object-fit: cover;
}

.service-item h1{
    font-weight: 600;
    font-size: 2.25rem;
    letter-spacing: 0.15rem;
    text-align: center;
    color: var(--primary);
    margin: 0;
    text-transform: uppercase;
}

.service-item h2{
    margin: 0;
    font-weight: 400;
    font-size: 1.25rem;
    letter-spacing: 0.15rem;
    text-transform: uppercase;
}

.service-item .socials a{
    display: flex;
    gap: 0.5em;
    margin-left: 0.5em;
    margin-bottom: 0.5em;
}

.service-item a{
    margin: 0 auto;
    text-decoration: none;
    color: #fff;
    background-color: #000;
    padding: 1em 2em;
    text-align: center;
    width: fit-content;
}

.service-item a:hover{
    background-color: var(--primary);
}

.service-item__details{
    padding: 1em;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
}

.service-item__details p{
    color: #000;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}