.phone-container{
    overflow: hidden;
    aspect-ratio: 1/2.2;
    width: 10vw;
    padding: 1em;
    overflow: hidden;
}

.phone-container::before{
    content: '';
    width: 10vw;
    aspect-ratio: 1/2.2;
    position: absolute;
    z-index: 2;
    background-image: url('/public/images/samsung.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.phone-container img{
    height: 90%;
    width: 95%;
    position: relative;
    top: 4%;
    left: 2.5%;
    z-index: 1;
    object-fit: cover;
}


/* Mobile */
@media screen and (max-width: 768px){
    .phone-container{
        width: 40vw;
    }

    .phone-container::before{
        width: 40vw;
    }
}