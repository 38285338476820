.side-heading-text{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 5% 0;
}

.sht-heading{
    font-size: 1.5em;
    margin:0 5ch;
    text-align: center;
}

.sht-heading > *{
    margin: 0;
    white-space: nowrap;
}

.sht-heading *:nth-child(1){
    font-weight: 300;
    font-size: 1em;
}

.sht-heading *:nth-child(2){
    color:var(--primary);
    font-size: 3.5rem;
    letter-spacing: 0.4ch;
}

.side-heading-text.color-block{
    background-color: var(--primary);
    color: #fff;
}

.side-heading-text.color-block .sht-heading *:nth-child(2){
    color: #fff;
}

/* Mobile */
@media screen and (max-width: 768px){
    .side-heading-text{
        flex-direction: column;
        gap: 1em;
        text-align: center;
        padding: 1em;
        box-sizing: border-box;
    }

    .sht-heading{
        margin: 0;
        font-size: 1.2em;
    }

    .sht-heading *:nth-child(2){
        font-size: 2.5rem;
    }
}