.search-input{
    display: flex;
    align-items: center;
    gap: 0.625em;
    border: 1px solid #ccc;
    padding: 0.6rem;
    transition-duration: 0.1s;
}

.search-input > *{
    margin: 0;
}

.search-input input{
    outline: none;
    border: none;
    flex: 1;
}

.search-input svg, .search-input .divider, .search-input input::placeholder{
    color: #ccc;
    transition-duration: 0.1s;
}

.search-input:focus-within svg{ 
    width: 1.15em;
    height: 1.15em;
    color: var(--primary);
}

.search-input:focus-within{
    border: 2px solid var(--primary);

}