@keyframes hide-services{
    from{
        height: 100%;
        opacity: 1;
    }
    to{
        height: 0;
        opacity: 1;
    }
}

.category-header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 1em;
    cursor: pointer;
}

.category-header > hr{
    flex: 1;
    border-top: 1px solid #ccc;
    margin: 0 1em;
}

.category-header > p{
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
    text-align: center;
    text-transform: uppercase;
}

.services-container{
    width: 100%;
    padding: 1em;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    flex-direction: row;
    gap: 1rem;
    transition-duration: 0.2s;
    animation: hide-services 0.2s ease-in-out;
}
.hidden > .services-container{
    height: 0;
    opacity: 0;
    pointer-events: none;
}


/* Category List */
.categories-list{
    display: grid;
    width: 95vw;
    grid-template-columns: repeat(4, 25%);
    margin: 0 auto;
}

.categories-list > *{
    width: max-content;
    text-transform: capitalize;
    padding: 1em 0;
}