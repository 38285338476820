.gallery-content{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 1em;
    margin-top: 11ch;
    margin-bottom: 1em;
    gap: 1em;
    width: 100%;
}

.gallery-content img{
    width: 32%;
    object-fit: cover;
}

.gallery-whakatauki{
    font-size: 1.8rem;
    font-family: var(--primary-font);
    font-style: italic;
    width:100%;
    text-align: center;
    padding: 1em;
}

.gallery-whakatauki p:nth-child(2){ /* Maori */
    font-weight: 600;
    margin-bottom: 0.25rem;
}

.gallery-whakatauki p:nth-child(3){
    color: #888;
}

.gallery-triangle{
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    
    border-bottom: 5px solid black;
}

.flip{
    transform:rotate(180deg)
}

/* Mobile */
@media screen and (max-width: 768px){
    .gallery-content img{
        width: 100%;
    }

    .gallery-whakatauki{
        box-sizing: border-box;
        margin-top: 10vh;
    }
}