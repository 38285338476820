@keyframes space-out{
    0%{
        letter-spacing: -0.1ch;
        opacity: 0;
    }
    100%{
        letter-spacing: 0.4ch;
        opacity: 1;
    }
}

@keyframes bg-move{
    0%{
        background-position: 0 180%;
    }
    100%{
        background-position: 0 -180%;
    }
}

.partners{
    display: flex;
    justify-content: space-evenly;
    width:100%;
    flex-wrap: wrap;
    padding: 1em 7em;
    box-sizing: border-box;
}

.partners-label{
    width: 100%;
    text-align: center;
    font-weight: 200;
    font-size: 1.5em;
    margin: 1ch;
    color: #999;
}

/* ---- Heading ---- */

.heading{
    margin: 2em 0;
    align-items: flex-start;
    text-align: center;
}

.heading > *{
    margin: 0;
    justify-content: flex-start;
    align-items: flex-start;
    width:100%;
    white-space: nowrap;
}

.heading *:nth-child(1){
    font-weight: 400;
}

.heading *:nth-child(2){
    color:var(--primary);
    letter-spacing: 0.4ch;
}

.heading h1{
    font-size: 350%;
}

.heading.center{
    width: 100%;
    text-align: center;
}

.flex-horizontal .heading{
    padding: 0 5em;
    margin-top: 0;
}

/* ---- Text Section ---- */

.text-section{
    padding: 0 10%;
    height: 100%;
    gap: 2em;
    box-sizing: border-box;
}

.text-section p{
    margin: 0;
}

.flex-horizontal.text-section{
    align-items: center;
}

/* ---- Video ---- */
/* (Might be removed soon...) */
.video{
    margin:2em 0;
    margin-bottom: 0;
}

.video > iframe{
    height: calc(100vw * 0.5625 / 2);
    width: 100vw;
    border: none;
}

/* ---- Section ---- */

.section.primary{
    background-color: var(--primary);
    background-repeat: repeat-y;
    background-image: url('http://localhost:3000/images/s.svg');
    background-size: 105%;
    background-blend-mode:soft-light;
    animation: bg-move 100s linear infinite;
    
    color: var(--secondary);
    
}

.section.primary h2:nth-child(1){
    font-weight: 200;
}

.section.primary h1,.section.primary h2{
    color:#fff;
}

h1.space-out{
    animation: space-out 1s ease-in-out;
}

.whanau-img{
    height: 50vh;
    margin: 0;
    padding: 0;
    object-position: center;
    object-fit: cover;
}

.map-view{
    height: 70vh;
    width: 100%;
    margin-top: 2vh;
    padding: 0;
    object-position: center;
}

/* Mobile */
@media only screen and (max-width: 768px) {
    p,a{
        font-size: var(--mobile-medium); /* <-- standardize font sizes */
    }
    
    .text-section{
        padding: 0 3%;
        text-align: center;
    }

    .heading h1{
        font-size: var(--mobile-large);
    }

    .heading h1.space-out{
        font-size: 9vw; /* Welcome to Whanau Ora heading */
    }

    .heading h2{
        font-size: 4vw;
    }

    .section.primary img{
        height: 30vh;
    }

    .section.primary{
        height:auto;
    }

    .section.primary .flex-horizontal{
        display: block; /* ^^ Didn't think this one through... */
        width: 100vw;
        padding: 1em !important;
        text-align: center;
    }

    .section.primary .flex-horizontal .heading{
        padding: 0 2em;
    }

    .section.primary .flex-horizontal .heading h1{
        font-size: 6vw;
        margin-bottom: 2vh;
    }

    .section.primary .flex-horizontal .heading h2{
        font-size: 4vw;
    }

    .section.primary .flex-horizontal p{
        font-size: var(--mobile-medium);
    }
}