.partner-icon{
    position: relative;
    width: 5em;
    height: 5em;
    aspect-ratio: 1 / 1;
    cursor: pointer;
}

.partner-icon > img{
    object-fit: contain;
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
}

.partner-icon .tooltip{
    position: absolute;
    top: -75%;
    left: 75%;
    width: max-content;
    padding: 1em;
    background-color: #00000080;
    box-shadow: 0 0 1em #00000020;
    color: #fff;
    opacity: 0;
    text-align: center;
    transition: all 0.2s ease-in-out;
}

.partner-icon .tooltip *{
    margin: 0;
}

.partner-icon:hover .tooltip{
    opacity: 1;
}