.whanau-stories-container{
    display: flex;
    flex-direction: column;
    padding: 0 1em;
    gap: 1em;
}

.ws-multi-cont{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
    margin-top: 0.5rem;
}

.whanau-story{
    display: flex;
    flex-direction: row;
    border: thin solid #ccc;
    gap:0.5em;
    color: #000;
    text-decoration: none;
    padding: 0.25em;
    transition-duration: 0.2s;
}

.whanau-story:hover{
    background-color: rgba(var(--primary-rgb),0.25);
    color:#222;
}

.whanau-story .ws-content{
    display: flex;
    height: 20%;
    flex-direction: column;
    gap: 0.25em;
    padding: 0.25em;
    margin-top: 1rem;
}

.whanau-story img{
    width: 20%;
    object-fit: cover;
    aspect-ratio: 1/1;
    padding: 0.25em;
}

.whanau-story h1{
    font-size: 2rem;
    margin: 0;
    letter-spacing: 0.1em;
    font-weight: 600;
}

.whanau-story .ws-date{
    margin: 0;
    color:#888;
    transition-duration: 0.2s;
}

.whanau-story:hover .ws-date{
    color:#333;
}

.whanau-story .ws-content p{
    display: -webkit-box;
    margin: 0;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.ws-multi-cont > .whanau-story{
    width: calc(100% / 3 - 1.2785em); /* wtf lol */
    flex-direction: column;
    height: fit-content;
    overflow: hidden;
}

.ws-multi-cont > .whanau-story img{
    width: calc(100% - 0.5em);
    height: 70%;
    aspect-ratio:initial;
    object-fit: cover;
    padding: 0.25em;
}

/* Story Page */
.story-page{
    display: flex;
    flex-direction: column;
    padding: 0 1em;
    margin-top: 11ch;
}

.story-page .cover-img{
    display: flex;
    align-items: flex-end;
    background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.5)), url("https://via.placeholder.com/1920x1080");
    object-fit: cover;
    height: 60vh;
    padding: 0 2em;
    text-transform: uppercase;
    color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.story-page .headline{
    font-family: var(--primary-font);
    font-style: italic;
    font-weight: 500;
}

.story-page .headline::before{
    content:"“";
    font-size: 2rem;
}

.story-page .headline::after{
    content:"”";
    font-size: 2rem;
}

.story-page .body .section{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    gap: 1em;
    margin-top: 1em;
}

.story-page .body .section img{
    height: 65vh;
    object-fit: cover;
    aspect-ratio: 1/1;
    padding: 0.25em;
}