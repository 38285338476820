@keyframes fade-in-arrows{
    from{
        opacity: 0.1;
        transform: translateY(20%);
    }
    to{
        opacity: 1;
        transform: translateY(5%);
    }
}

@keyframes fade-in{
    from{
        opacity: 0.1;
        transform: translateY(20%);
    }
    to{
        opacity: 1;
        transform: translateY(0%);
    }
}

.jumbotron{
    height: 70vh;
    overflow: hidden;
    background-image: linear-gradient(90deg, rgba(var(--primary-rgb), 0.5) 0%, rgba(0,0,0,0) 55%),
    var(--bgi); /* Background Image */
    background-size: cover;
    background-position: bottom;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2em;
    color: #fff;
}

.jumbotron .flex-vertical{
    transform: translateY(5%);
    animation: fade-in-arrows 1s ease-in-out;
    min-width: 10vw;
}

.jumbotron h1{
    font-size: 3.5rem;
    font-weight: 600;
    letter-spacing: 0.4rem;
    text-transform: uppercase;
}

.jumbotron img{
    filter: invert();
}

/* Mobile */
@media screen and (max-width: 768px){
    .jumbotron{
        height: 50vh;
        flex-direction: column;
        justify-content: center;
        gap: 1em;
        text-align: center;
    }

    .jumbotron h1{
        font-size: 2.5rem;
        letter-spacing: 0.2rem;
        animation: fade-in 1s ease-in-out;
    }

    .flex-vertical{
        display: none;
    }
}