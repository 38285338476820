/* Fonts */
/* Raleway */
@import url('https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,700&display=swap');
/* Inter */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');

/* Bebas Neue */
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

/* Arimo */
@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@400;700&display=swap');

:root{
    /* Colors */
    --primary: #a51e22;
    --primary-rgb: 165, 30, 35;
    
    --secondary: #fff;
    
    --light-text: #fff;
    --dark-text: #000;
    
    --light-bg: #fff;
    --dark-bg: #000;

    /* Fonts */
    --primary-font: Raleway, sans-serif;
    --secondary-font: Inter, sans-serif;
    /* Font Sizes */
    --mobile-medium: 3vw;
    --mobile-large: 7vw;
    /* Miscelaneous Font Settings */
    --letter-spacing: 0.2em;
}

#root{
    width: 100%;
    height: 100%;
  overflow: hidden;
}

h1,h2,h3,h4,h5,h6{
    font-family: var(--primary-font);
}

p,input,textarea{
  font-family: var(--secondary-font);
}

a{
    font-family: var(--secondary-font);
    color: var(--primary);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex-horizontal{
    display: flex;
    flex-direction: row;
}

.flex-vertical{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}