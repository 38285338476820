
.tuponotia-heading{ /* Styled to fit app theme */
    font-size: 5rem;
    font-weight: bold;
    font-family: 'Bebas Neue', sans-serif;
    letter-spacing: 0.1em;
    text-align: center;
    color: #a51e22;
    margin-bottom: 0;
}

.tuponotia-subheading{
    font-size: 1.5rem;
    font-weight: light;
    font-family: 'Arimo', sans-serif;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-top: 0;
    text-align: center;
    color: #333;
}

.tuponotia-button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5em;
    
    background-color: #3DDC84;

    padding: 1em 2em;
    font-size: 0.7rem;
    color: #fff;
    width: fit-content;
}

.tuponotia-button img{
    width: 1.5em;
}

.tuponotia-download-container{
    display: flex;
    flex-direction: column;
    padding: 0 1em;
    gap: 1em;
}

.tuponotia-divider{
    width: 100%;
    height: 1px;
    border-top: 2px dashed #333;
    background-color: transparent;
    margin: 0;
    padding: 1em 0;
}

.tuponotia-split-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 1em 10em;
}

.tuponotia-split-container .tuponotia-sc-text{
    width: 50%;
}

.tuponotia-split-container .tuponotia-subheading{
    text-align: left;
}

.tuponotia-tri{
    transform: rotate(90deg);
    position: relative;
    top: 0.5rem;
}

.tuponotia-tri-right{
    transform: rotate(-90deg);
    position: relative;
    top: 0.48rem;
}

/* Mobile */
@media screen and (max-width: 768px){
    .tuponotia-split-container{
        flex-direction: column-reverse;
        padding: 1em;
    }

    .tuponotia-split-container:nth-child(odd){
        flex-direction: column;
    }

    .tuponotia-split-container .tuponotia-subheading{
        text-align: center;
    }

    .tuponotia-split-container .tuponotia-sc-text{
        width: 100%;
    }

    .tuponotia-tri{
        transform: rotate(0deg);
        position: relative;
        top: 0;
    }

    .tuponotia-tri-right{
        transform: rotate(0deg);
        position: relative;
        top: 0;
    }

    .tuponotia-paragraph{
        text-align: center;
    }

    .tuponotia-heading{
        box-sizing: border-box;
    }

    .tuponotia-subheading{
        box-sizing: border-box;
    }
}