header{
    position: fixed;

    width: 100%;
    height: 12ch;
    background-color: rgba(255, 255, 255, 0);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.05) 100%);
    
    display: flex;
    align-items: center;
    gap: 5em;
    
    font-family: var(--primary-font);
    
    padding: 1em 2em;
    box-sizing: border-box;
    border-bottom: thin solid #ccc;
    
    z-index: 100;
    backdrop-filter: blur(5px);
    transition-duration: 0.2s;
}

header .logo{
    display: flex;
    align-items: center;
    gap: 1ch;
    text-decoration: none;
}

header .logo img{
    width: 17.5em;
    transition-duration: 0.2s;
}

.logo-text h5{
    font-weight: 400;
    color: var(--light-text);
    margin: 0;
    transition-duration: 0.2s;
}

header h1{
    font-size: 1.75em;
    font-weight: 400;
    letter-spacing: 0.2em;
    color: var(--light-text);
    margin: 0;
    transition-duration: 0.3s;
}

nav{
    flex:1;
    padding: 0 5em;
}

nav ul{
    display: flex;
    font-weight: 500;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
}

nav ul li, nav ul li a{
    padding: 0.5em 0;
    text-decoration: none;
    color: #fff;
    transition-duration: 0.2s;
}

nav ul li a:hover{
    color: var(--primary);
}

/* Scroll */
header[scroll="true"]{
    height: 9ch;
    background-color: rgba(255, 255, 255, 1);
    background-image: none;
}

header[scroll="true"] h1{
    color: var(--dark-text);
    transform: translateY(0.5ch);
}

header[scroll="true"] .logo-text h5{
    opacity: 0;
}

header[scroll="true"] .logo img{
    width: 10em;
}

header[scroll="true"] nav ul li a{
    color: #000;
}

header[scroll="true"] nav ul li a:hover{
    color: var(--primary);
}

/* Mobile */
header.mobile{
    width: 100%;
    flex-direction: column;
    justify-content: center;
}

.logo.mobile{
    display: flex;
    flex-direction: column;
    gap:0;
    font-size: 0.75em;
}

.logo.mobile h1{
    font-weight: 400;
    color: var(--light-text);
    margin: 0;
    font-size: 1.5em;
}

.logo.mobile img{
    width: calc(100vw * 9/16 / 2);
}

/* Mobile Scroll */
header.mobile[scroll="true"] h1{
    color: var(--dark-text);
    transform: none;
}

.header-mobile-selector{
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.4);
}

.header-mobile-selector.active{
    display: flex;
}

.header-mobile-selector ul{
    display: block;
    
}

.header-mobile-selector li a{
    color: #fff !important;
    text-align: center !important;
}