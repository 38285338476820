@keyframes letter-animate {
    0%{
        opacity: 0;
        transform: translateY(-0.5ch);
    }

    100%{
        opacity: 1;
        transform: translateY(0ch);
    }
}

.jumbotron-container{
    text-align: center;
    width: 100%;
    height: 75vh;
    overflow: hidden;
    color:#fff;
    background-color: #000;
}

.jumbotron-video{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;
    mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 80%, rgba(0,0,0,0) 100%)
}

.jumbotron-container h1{
    font-weight: 300;
    letter-spacing: 0.25em;
    position: relative;
    z-index: 2;
    top: -3ch;
    margin:0;
    animation: letter-animate 1s ease-in-out;
}

/* Mobile */
@media screen and (max-width: 768px){
    .jumbotron-container{
        height: 50vh;
    }

    .jumbotron-container h1{
        font-size: 5vw;
        top: -5ch;
    }

    .jumbotron-container h1 span{
        display: block;
    }

    .jumbotron-video{
        mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%)
    }
}